<template>
  <div class="border-top-info border-3 box-shadow-3 rounded-bottom">
    <filter-slot
      annulled
      :fields="fields"
      :filter="filter"
      :total-rows="paginate.totalRows"
      :paginate="paginate"
      :start-page="paginate.startPage"
      :to-page="paginate.toPage"
      :filter-principal="filterPrincipal"
      @reload="$refs['annull-table'].refresh()"
    >
      <b-table
        id="annull-table"
        slot="table"
        ref="annull-table"
        :has-provider="true"
        sticky-header="70vh"
        no-provider-filtering
        class="font-small-3 text-center"
        :busy.sync="isBusy"
        :items="myProvider"
        :fields="visibleFields"
        :per-page="paginate.perPage"
        :current-page="paginate.currentPage"
      >
        <template #cell(action)="data">
          <feather-icon
            icon="EditIcon"
            class="mr-50 text-center cursor-pointer"
            @click="OpenModalJustify(data.item)"
          />
        </template>
        <template #cell(date)="data">
          {{ data.item.date | myGlobal }}
        </template>
        <template #cell(type)="data">
          {{ data.item.type == "late" ? "LATE" : "ABSENCE" }}
        </template>
        <template #cell(sent_by)="data">
          {{ data.item.name_super }} <br />
          {{ data.item.received | myGlobal }}
        </template>
        <template #cell(file)="data">
          <feather-icon
            icon="FolderIcon"
            :class="
              data.item.type == 'abse' && data.item.isRrhh == 0
                ? 'cursorFile'
                : ''
            "
            @click="openFiles(data.item)"
          />
        </template>
      </b-table>
    </filter-slot>
    <b-modal
      v-model="modalJustify"
      :title="titleModal"
      size="lg"
      body-class="p-0"
      title-class="title-assitence-schedule"
    >
      <div>
        <content-justify
          :item="item"
          ref="content"
          @closeModalJustify="closeModalJustify"
        />
      </div>
      <template #modal-footer>
        <div class="footer-capture">
          <b-button variant="primary" @click="sendJustify()">
            <feather-icon icon="SendIcon" class="mr-50" />SEND
          </b-button>
        </div>
      </template>
    </b-modal>
    <b-modal
      v-model="modalFiles"
      :title="titleModal"
      size="lg"
      body-class="p-0"
      title-class="title-assitence-schedule"
      hide-footer
    >
      <div>
        <view-files :files="files" />
      </div>
    </b-modal>
  </div>
</template>
<script>
import SchedulesServices from "../services/schedules.service";
import ContentJustify from "../modals/contentJustify.vue";
import dataFilters from "../filters/filters.data";
import viewFiles from "../modals/contentViewFile.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    ContentJustify,
    viewFiles,
  },
  data() {
    return {
      fields: [
        {
          key: "module",
          label: "module",
          visible: true,
        },
        {
          key: "user_name",
          label: "user",
          visible: true,
        },
        {
          key: "date",
          label: "date",
          visible: true,
        },
        {
          key: "type",
          label: "type",
          visible: true,
        },
        {
          key: "sent_by",
          label: "Sent By",
          visible: this.moduleId == 17 ? true : false,
        },
        {
          key: "file",
          label: "Files",
          visible: this.moduleId == 17 ? true : false,
        },
        {
          key: "action",
          label: "action",
          visible: true,
        },
      ],
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "User...",
        model: null,
      },
      items: [],
      filter: dataFilters,
      paginate: {
        perPage: 10,
        currentPage: 1,
        startPage: null,
        toPage: null,
        totalRows: 0,
      },
      isBusy: false,
      modalJustify: false,
      titleModal: null,
      item: {},
      modalFiles: false,
      files: [],
    };
  },
  mounted() {},
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    isRrhh() {
      return this.moduleId == 17;
    },
    visibleFields() {
      return this.fields.filter((field) => field.visible);
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  methods: {
    async myProvider(ctx) {
      try {
        this.addPreloader();
        let params = {};
        if (this.moduleId != 17) {
          params = {
            from: this.filter[0].model,
            to: this.filter[1].model,
            user_name: this.filterPrincipal.model,
            modul_id: this.moduleId,
            per_page: this.paginate.perPage,
            page: ctx.currentPage,
          };
        } else {
          params = {
            page: 1,
            moduleid: null,
            username: this.filterPrincipal.model,
            from: this.from,
            to: this.to,
            moduleLogged: this.moduleId,
          };
        }
        const data =
          this.moduleId != 17
            ? await SchedulesServices.getPendingJustify(params)
            : await SchedulesServices.searchPendingJustify(params);
        this.paginate.startPage = data.from;
        this.paginate.toPage = data.to;
        if (this.paginate.totalRows !== data.total)
          this.paginate.totalRows = data.total;
        let array = data.data;
        let items = [];
        array.forEach((element) => {
          items.push({
            id: element.id,
            user_id: element.id_user,
            modul_id: element.module_id,
            module: element.module,
            user_name: element.nameuser,
            date: element.date,
            type: element.type,
            isRrhh: element.isRrhh,
            motive: element.motive,
            sent_by: null,
            name_super:
              this.moduleId == 17 ? element.name_super : null,
            received: this.moduleId == 17 ? element.received : null,
            file: null,
            cmm: element.cmm,
          });
        });
        this.removePreloader();
        return items;
      } catch (e) {
        this.showToast("danger", "top-right", "Error", "XIcon", e);
        this.removePreloader();
        return [];
      }
    },
    async openFiles(item) {
      if (item.type == "abse" && item.isRrhh == 0) {
        this.addPreloader();
        this.titleModal = item.user_name;
        const params = { id: item.id };
        const data = await SchedulesServices.getFiles(params);
        this.files = data;
        this.modalFiles = true;
        this.removePreloader();
      }
    },
    OpenModalJustify(item) {
      this.titleModal = item.user_name;
      this.item = item;
      this.modalJustify = true;
    },
    sendJustify() {
      this.$refs.content.sendJustify();
    },
    closeModalJustify() {
      this.modalJustify = false;
      this.$refs["annull-table"].refresh();
    },
  },
};
</script>
<style>
.cursorFile {
  color: #dfb15a !important;
  fill: #dfb15a !important;
  cursor: pointer;
}
</style>

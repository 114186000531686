var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',[(_vm.typeJustify == 2 && _vm.moduleId != 17)?_c('div',[_c('validation-observer',{ref:"form"},[_c('b-form-group',{staticClass:"my-2"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-form-textarea',{attrs:{"id":"textarea","placeholder":"Justification...","rows":"3","max-rows":"6","state":errors[0] ? false : valid ? true : null},model:{value:(_vm.justify.motive),callback:function ($$v) {_vm.$set(_vm.justify, "motive", $$v)},expression:"justify.motive"}})]}}],null,false,1418601764)})],1),_c('b-form-group',{staticClass:"my-2"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-form-select',{attrs:{"options":_vm.options,"state":errors[0] ? false : valid ? true : null},model:{value:(_vm.justify.status),callback:function ($$v) {_vm.$set(_vm.justify, "status", $$v)},expression:"justify.status"}})]}}],null,false,1932210270)})],1),_c('b-form-group',{staticClass:"my-2"},[_c('drag-and-drop',{attrs:{"filesArray":_vm.files},model:{value:(_vm.files),callback:function ($$v) {_vm.files=$$v},expression:"files"}})],1)],1)],1):_c('div',[_c('validation-observer',{ref:"form"},[_c('b-form-group',{staticClass:"my-2"},[_c('small',[_vm._v("Motive")]),_c('b-form-textarea',{attrs:{"id":"textarea","rows":"3","max-rows":"6","disabled":""},model:{value:(_vm.item.motive),callback:function ($$v) {_vm.$set(_vm.item, "motive", $$v)},expression:"item.motive"}})],1),(_vm.moduleId == 17 && _vm.item.type == 'late')?_c('b-form-group',{staticClass:"my-2"},[_c('small',[_vm._v("Supervisor")]),_c('b-form-textarea',{attrs:{"id":"textarea","disabled":"","placeholder":"Justification...","rows":"3","max-rows":"6"},model:{value:(_vm.item.cmm),callback:function ($$v) {_vm.$set(_vm.item, "cmm", $$v)},expression:"item.cmm"}})],1):_vm._e(),(_vm.moduleId == 17)?_c('b-form-group',{staticClass:"my-2"},[_c('small',[_vm._v("RRHH")]),_c('b-form-textarea',{attrs:{"id":"textarea","rows":"3","max-rows":"6"},model:{value:(_vm.rrhhReason),callback:function ($$v) {_vm.rrhhReason=$$v},expression:"rrhhReason"}})],1):_vm._e(),_c('b-form-group',{staticClass:"my-2"},[_c('small',[_vm._v("Status")]),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-form-select',{attrs:{"options":_vm.options,"state":errors[0] ? false : valid ? true : null},model:{value:(_vm.justify.status),callback:function ($$v) {_vm.$set(_vm.justify, "status", $$v)},expression:"justify.status"}})]}}])})],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }